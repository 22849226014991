import clsx from "clsx";
import { CSSProperties, ReactNode } from "react";
import "./analyticsCardRoot.scss";

type AnalyticsCardRootProps = {
    style?: CSSProperties;
    className?: string;
    children: ReactNode;
};

export function AnalyticsCardRoot(props: AnalyticsCardRootProps) {
    return <div {...props} className={clsx("analytics-card-root", props.className)} />;
}
